<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <b-form @submit.prevent="updateProfile">
          <div class="row">
            <h5 class="col-12 text-center mt-3">
              {{ $t('edit_basic_information') }}
            </h5>
            <div class="col-12 text-center mt-3">
              <span class="position-relative">
                <b-avatar :src="profile" size="10rem"></b-avatar>
                <b-avatar class="custom-edit-avatar" variant="primary" size="2.5rem">
                  <i class="ri-pencil-fill"></i>
                </b-avatar>
              </span>
            </div>
            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('name.label') }}
                  <span v-if="!$v.name.required" class="text-danger">*</span>
                </h6>

                <get-help modal_id="modal-name" title="What do we mean by 'name'" :content="$t('name.help')" />
              </div>
              <b-form-input
                v-model.trim="$v.name.$model"
                size="sm"
                :class="{ error: $v.name.$error }"
                placeholder
                type="text"
              ></b-form-input>
              <div class="small text-light">{{ $t('name.example') }}</div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('phone_number.label') }}
                  <span v-if="!$v.phone.required || !$v.phone.minLength || !$v.phone.maxLength" class="text-danger"
                    >*</span
                  >
                </h6>
                <get-help modal_id="modal-phone" title="What do we mean by 'name'" :content="$t('phone_number.help')" />
              </div>
              <b-form-input
                v-model.trim="$v.phone.$model"
                v-mask="'##########'"
                size="sm"
                :class="{ error: $v.phone.$error }"
                placeholder
                type="number"
                disabled
              ></b-form-input>
              <div class="small text-light">
                {{ $t('phone_number.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('date_of_birth.label') }}
                  <span v-if="!$v.dob.required" class="text-danger">*</span>
                </h6>
                <get-help modal_id="modal-dob" title="What do we mean by 'name'" :content="$t('date_of_birth.help')" />
              </div>

              <div class="position-relative">
                <b-form-input
                  v-model.trim="$v.dob.$model"
                  v-mask="'##-##-####'"
                  size="sm"
                  :class="{ error: $v.dob.$error }"
                  disabled
                ></b-form-input>

                <!-- <i
                class="append-icon ri-calendar-2-line ri-lg"
                @click="showDatePicker()"
              ></i>-->
              </div>

              <!-- <b-modal
              id="modal-date-picker"
              centered
              scrollable
              size="sm"
              hide-header
              footer-class="m-0 p-2"
              content-class="border-0 w-auto"
              cancel-variant="transparent"
              ok-variant="transparent text-primary"
              buttonSize="sm"
              ok-only
            >
              <VueDatePicker
                v-model="date"
                :format="format"
                :min-date="minDate"
                :max-date="maxDate"
                color="#2E3192"
                inline
              >
              </VueDatePicker>
            </b-modal>-->
              <div class="small text-light">
                {{ $t('date_of_birth.label') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('province_of_work.label') }}
                  <!-- <span v-if="!$v.province_work.required" class="text-danger"
                  >*</span
                >-->
                </h6>
                <get-help
                  modal_id="modal-pow"
                  title="What do we mean by 'name'"
                  :content="$t('province_of_work.help')"
                />
              </div>

              <!-- <multiselect
              v-model.trim="$v.province_work.$model"
              :class="{ error: $v.province_work.$error }"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              :placeholder="$t('select_one')"
              :options="province_work_options"
              :searchable="false"
              :allow-empty="false"
              deselectLabel=""
              :showLabels="false"
              disabled
            >
              <template slot="singleLabel" slot-scope="{ option }"
                >{{ option.name }}
              </template>
            </multiselect>-->
              <b-form-input v-model="province_work_model" size="sm" disabled></b-form-input>

              <div class="small text-light">
                {{ $t('province_of_work.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('province_of_residence.label') }}
                  <!-- <span
                  v-if="!$v.province_resident.required"
                  class="text-danger"
                  >*</span
                >-->
                </h6>
                <get-help
                  modal_id="modal-por"
                  title="What do we mean by 'name'"
                  :content="$t('province_of_residence.help')"
                />
              </div>
              <!-- <multiselect
              v-model.trim="$v.province_resident.$model"
              :class="{ error: $v.province_resident.$error }"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              :placeholder="$t('select_one')"
              :options="province_resident_options"
              :searchable="false"
              :allow-empty="false"
              deselectLabel=""
              :showLabels="false"
              disabled
            >
              <template slot="singleLabel" slot-scope="{ option }"
                >{{ option.name }}
              </template>
            </multiselect>-->

              <b-form-input v-model="province_resident_model" size="sm" disabled></b-form-input>

              <div class="small text-light">
                {{ $t('province_of_residence.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('income_type.label') }}
                  <span v-if="!$v.income_type.required" class="text-danger">*</span>
                </h6>
                <get-help modal_id="modal-income" title="What do we mean by 'name'" :content="$t('income_type.help')" />
              </div>
              <multiselect
                v-model.trim="$v.income_type.$model"
                :class="{ error: $v.income_type.$error }"
                track-by="key"
                label="name"
                :placeholder="$t('select_one')"
                :options="income_type_options"
                :searchable="false"
                :allow-empty="false"
                deselect-label=""
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <div class="small text-light">
                {{ $t('income_type.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('salary.label') }} (Baht)
                  <span v-if="!$v.salary.required" class="text-danger">*</span>
                </h6>
                <get-help modal_id="modal-salary" title="What do we mean by 'name'" :content="$t('salary.help')" />
              </div>
              <b-form-input
                v-model.trim="$v.salary.$model"
                v-mask="'########'"
                type="number"
                size="sm"
                :class="{ error: $v.salary.$error }"
                placeholder
              ></b-form-input>
              <div class="small text-light">{{ $t('salary.example') }}</div>
            </div>

            <div class="col-12 py-4 text-center">
              <b-button type="submit" pill variant="primary px-4">
                {{ $t('continue') }}
              </b-button>
            </div>
          </div>
        </b-form>

        <loading :is-busy="isBusy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import '@/assets/scss/vue-datepicker.min.css'
import '@/assets/scss/vue-multiselect.min.scss'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import AccountService from '@/api/service/AccountService'
import CommonService from '@/api/service/CommonService'
import dateFormat from '@/mixins/dateFormat.js'

import GetHelp from '@/components/GetHelp'
import Loading from '@/components/Loading.vue'

const date = new Date()
export default {
  components: {
    Multiselect,
    GetHelp,
    Loading,
  },
  mixins: [dateFormat],
  data() {
    return {
      profile: require('@/assets/images/profile_medium.png'),
      name: null,
      phone: null,
      date,
      minDate: `${date.getFullYear() - 100}-${date.getMonth() + 1}-${date.getDate()}`,
      maxDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      format: 'DD-MM-YYYY',
      dob: null,
      selectedDate: null,
      province_work: null,
      province_resident: null,
      income_type: null,
      salary: null,
      social_media: null,
      province_work_options: [],
      province_resident_options: [],
      income_type_options: [],
      confirm_items: [],
      isBusy: false,
      errorMessageList: [],

      province_work_model: null,
      province_resident_model: null,
    }
  },
  watch: {
    date: function (val) {
      if (val) {
        this.dob = this.dateFormatter(val, 'DD-MM-YYYY')
        this.selectedDate = val
      }
    },
  },
  created() {
    this.getInitData()
  },
  methods: {
    showDatePicker() {
      this.$bvModal.show('modal-date-picker')
    },
    agreeTermCod() {
      this.$bvModal.hide('modal-term-cod')
      this.accept = true
    },
    async getUserProfile() {
      try {
        this.isBusy = true
        const res = await AccountService.getUserProfile()
        this.isBusy = false
        if (res.status == 200) {
          const profileResponse = res.data.data
          this.name = profileResponse.name
          this.phone = profileResponse.phone_number
          this.dob = profileResponse.dob
          this.selectedDate = profileResponse.dob
          this.salary = profileResponse.salary

          this.initIncomeType(profileResponse.income_type.toLowerCase())
          this.initProvinceWork(profileResponse.province_of_work)
          this.initProvinceResident(profileResponse.province_of_resident)
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getInitData() {
      try {
        this.isBusy = true
        const provinceResponse = await CommonService.getProvince()
        const incomeTypeResponse = await CommonService.getIncomeType()

        const provinces = provinceResponse.data.data
        const incomeType = incomeTypeResponse.data.data

        provinces.forEach((province) => {
          if (province.status === 'show') {
            this.province_work_options.push({
              id: province.id,
              name:
                this.$i18n.locale == 'mm'
                  ? province.name_mm
                  : this.$i18n.locale == 'en'
                  ? province.name_en
                  : province.name_th,
            })
            this.province_resident_options.push({
              id: province.id,
              name:
                this.$i18n.locale == 'mm'
                  ? province.name_mm
                  : this.$i18n.locale == 'en'
                  ? province.name_en
                  : province.name_th,
            })
          }
        })

        incomeType.forEach((income) => {
          this.income_type_options.push({
            key: income.key,
            name:
              this.$i18n.locale == 'mm' ? income.name_mm : this.$i18n.locale == 'en' ? income.name_en : income.name_th,
          })
        })

        this.getUserProfile()

        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    initIncomeType(key) {
      this.income_type_options.forEach((income_type) => {
        if (income_type.key === key) {
          this.income_type = income_type
        }
      })
    },
    initProvinceWork(id) {
      this.province_work_options.forEach((province) => {
        if (province.id == id) {
          this.province_work = province
          this.province_work_model = province.name
        }
      })
    },
    initProvinceResident(id) {
      this.province_resident_options.forEach((province) => {
        if (province.id == id) {
          this.province_resident = province
          this.province_resident_model = province.name
        }
      })
    },
    async updateProfile() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true
        try {
          await AccountService.updateProfile({
            name: this.name,
            phone_number: this.phone,
            dob: this.dateFormatter(this.selectedDate, 'YYYY-MM-DD'),
            province_of_work: this.province_work ? this.province_work.id : null,
            province_of_resident: this.province_resident ? this.province_resident.id : null,
            income_type: this.income_type ? this.income_type.key : null,
            salary: this.salary,
            email: this.social_media,
          })
          this.isBusy = false
          this.$router.replace({ path: 'profile/basic-information' })
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
  },
  validations: {
    name: {
      required,
    },
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    dob: {
      required,
    },
    // province_work: {
    //   required,
    // },
    // province_resident: {
    //   required,
    // },
    income_type: {
      required,
    },
    salary: {
      required,
    },
  },
}
</script>

<style></style>
